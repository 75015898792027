export const parseRawElectionResultUE2024 = (data: RawElectionResultUE2024[]): ElectionUE2024Result[] => {
    function convertPercentageToNumber(percentage: string): number {
      // Remplace la virgule par un point et supprime le symbole de pourcentage
      const normalized = percentage.replace(',', '.').replace('%', '');
      // Convertit la chaîne normalisée en nombre
      const number = parseFloat(normalized);
      return number;
    }
    
    return data.map(e => ({
      code_commune: e['Code commune'],
      label_commune: e['Libellé commune'],
      code_bv: e['Code BV'],
      inscrits: e['Inscrits'],
      votants: e['Votants'],
      votants_percentage: convertPercentageToNumber(e['% Votants']),
      abstentions: e['Abstentions'],
      abstentions_percentage: convertPercentageToNumber(e['% Abstentions']),
      exprimes: e['Exprimés'],
      exprimes_par_inscrits_percentage: convertPercentageToNumber(e['% Exprimés/inscrits']),
      exprimes_par_votants_percentage: convertPercentageToNumber(e['% Exprimés/votants']),
      blancs: e['Blancs'],
      blancs_par_inscrits_percentage: convertPercentageToNumber(e['% Blancs/inscrits']),
      blancs_par_votants_percentage: convertPercentageToNumber(e['% Blancs/votants']),
      nuls: e['Nuls'],
      nuls_par_inscrits_percentage: convertPercentageToNumber(e['% Nuls/inscrits']),
      nuls_par_votants_percentage: convertPercentageToNumber(e['% Nuls/votants']),
      voix1: e['Voix 1'],
      voix1_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 1']),
      voix1_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 1']),
      voix2: e['Voix 2'],
      voix2_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 2']),
      voix2_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 2']),
      voix3: e['Voix 3'],
      voix3_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 3']),
      voix3_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 3']),
      voix4: e['Voix 4'],
      voix4_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 4']),
      voix4_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 4']),
      voix5: e['Voix 5'],
      voix5_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 5']),
      voix5_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 5']),
      voix6: e['Voix 6'],
      voix6_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 6']),
      voix6_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 6']),
      voix7: e['Voix 7'],
      voix7_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 7']),
      voix7_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 7']),
      voix8: e['Voix 8'],
      voix8_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 8']),
      voix8_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 8']),
      voix9: e['Voix 9'],
      voix9_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 9']),
      voix9_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 9']),
      voix10: e['Voix 10'],
      voix10_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 10']),
      voix10_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 10']),
      voix11: e['Voix 11'],
      voix11_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 11']),
      voix11_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 11']),
      voix12: e['Voix 12'],
      voix12_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 12']),
      voix12_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 12']),
      voix13: e['Voix 13'],
      voix13_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 13']),
      voix13_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 13']),
      voix14: e['Voix 14'],
      voix14_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 14']),
      voix14_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 14']),
      voix15: e['Voix 15'],
      voix15_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 15']),
      voix15_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 15']),
      voix16: e['Voix 16'],
      voix16_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 16']),
      voix16_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 16']),
      voix17: e['Voix 17'],
      voix17_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 17']),
      voix17_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 17']),
      voix18: e['Voix 18'],
      voix18_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 18']),
      voix18_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 18']),
      voix19: e['Voix 19'],
      voix19_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 19']),
      voix19_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 19']),
      voix20: e['Voix 20'],
      voix20_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 20']),
      voix20_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 20']),
      voix21: e['Voix 21'],
      voix21_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 21']),
      voix21_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 21']),
      voix22: e['Voix 22'],
      voix22_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 22']),
      voix22_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 22']),
      voix23: e['Voix 23'],
      voix23_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 23']),
      voix23_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 23']),
      voix24: e['Voix 24'],
      voix24_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 24']),
      voix24_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 24']),
      voix25: e['Voix 25'],
      voix25_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 25']),
      voix25_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 25']),
      voix26: e['Voix 26'],
      voix26_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 26']),
      voix26_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 26']),
      voix27: e['Voix 27'],
      voix27_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 27']),
      voix27_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 27']),
      voix28: e['Voix 28'],
      voix28_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 28']),
      voix28_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 28']),
      voix29: e['Voix 29'],
      voix29_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 29']),
      voix29_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 29']),
      voix30: e['Voix 30'],
      voix30_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 30']),
      voix30_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 30']),
      voix31: e['Voix 31'],
      voix31_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 31']),
      voix31_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 31']),
      voix32: e['Voix 32'],
      voix32_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 32']),
      voix32_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 32']),
      voix33: e['Voix 33'],
      voix33_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 33']),
      voix33_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 33']),
      voix34: e['Voix 34'],
      voix34_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 34']),
      voix34_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 34']),
      voix35: e['Voix 35'],
      voix35_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 35']),
      voix35_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 35']),
      voix36: e['Voix 36'],
      voix36_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 36']),
      voix36_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 36']),
      voix37: e['Voix 37'],
      voix37_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 37']),
      voix37_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 37']),
      voix38: e['Voix 38'],
      voix38_par_inscrits_percentage: convertPercentageToNumber(e['% Voix/inscrits 38']),
      voix38_par_exprimes_percentage: convertPercentageToNumber(e['% Voix/exprimés 38']),
    }))
  }

  export type ElectionUE2024Result = {
    code_commune: string;
    label_commune: string;
    code_bv: number;
    inscrits: number;
    votants: number;
    votants_percentage: number;
    abstentions: number;
    abstentions_percentage: number;
    exprimes: number;
    exprimes_par_inscrits_percentage: number;
    exprimes_par_votants_percentage: number;
    blancs: number;
    blancs_par_inscrits_percentage: number;
    blancs_par_votants_percentage: number;
    nuls: number;
    nuls_par_inscrits_percentage: number;
    nuls_par_votants_percentage: number;
    voix1: number;
    voix1_par_inscrits_percentage: number;
    voix1_par_exprimes_percentage: number;
    voix2: number;
    voix2_par_inscrits_percentage: number;
    voix2_par_exprimes_percentage: number;
    voix3: number;
    voix3_par_inscrits_percentage: number;
    voix3_par_exprimes_percentage: number;
    voix4: number;
    voix4_par_inscrits_percentage: number;
    voix4_par_exprimes_percentage: number;
    voix5: number;
    voix5_par_inscrits_percentage: number;
    voix5_par_exprimes_percentage: number;
    voix6: number;
    voix6_par_inscrits_percentage: number;
    voix6_par_exprimes_percentage: number;
    voix7: number;
    voix7_par_inscrits_percentage: number;
    voix7_par_exprimes_percentage: number;
    voix8: number;
    voix8_par_inscrits_percentage: number;
    voix8_par_exprimes_percentage: number;
    voix9: number;
    voix9_par_inscrits_percentage: number;
    voix9_par_exprimes_percentage: number;
    voix10: number;
    voix10_par_inscrits_percentage: number;
    voix10_par_exprimes_percentage: number;
    voix11: number;
    voix11_par_inscrits_percentage: number;
    voix11_par_exprimes_percentage: number;
    voix12: number;
    voix12_par_inscrits_percentage: number;
    voix12_par_exprimes_percentage: number;
    voix13: number;
    voix13_par_inscrits_percentage: number;
    voix13_par_exprimes_percentage: number;
    voix14: number;
    voix14_par_inscrits_percentage: number;
    voix14_par_exprimes_percentage: number;
    voix15: number;
    voix15_par_inscrits_percentage: number;
    voix15_par_exprimes_percentage: number;
    voix16: number;
    voix16_par_inscrits_percentage: number;
    voix16_par_exprimes_percentage: number;
    voix17: number;
    voix17_par_inscrits_percentage: number;
    voix17_par_exprimes_percentage: number;
    voix18: number;
    voix18_par_inscrits_percentage: number;
    voix18_par_exprimes_percentage: number;
    voix19: number;
    voix19_par_inscrits_percentage: number;
    voix19_par_exprimes_percentage: number;
    voix20: number;
    voix20_par_inscrits_percentage: number;
    voix20_par_exprimes_percentage: number;
    voix21: number;
    voix21_par_inscrits_percentage: number;
    voix21_par_exprimes_percentage: number;
    voix22: number;
    voix22_par_inscrits_percentage: number;
    voix22_par_exprimes_percentage: number;
    voix23: number;
    voix23_par_inscrits_percentage: number;
    voix23_par_exprimes_percentage: number;
    voix24: number;
    voix24_par_inscrits_percentage: number;
    voix24_par_exprimes_percentage: number;
    voix25: number;
    voix25_par_inscrits_percentage: number;
    voix25_par_exprimes_percentage: number;
    voix26: number;
    voix26_par_inscrits_percentage: number;
    voix26_par_exprimes_percentage: number;
    voix27: number;
    voix27_par_inscrits_percentage: number;
    voix27_par_exprimes_percentage: number;
    voix28: number;
    voix28_par_inscrits_percentage: number;
    voix28_par_exprimes_percentage: number;
    voix29: number;
    voix29_par_inscrits_percentage: number;
    voix29_par_exprimes_percentage: number;
    voix30: number;
    voix30_par_inscrits_percentage: number;
    voix30_par_exprimes_percentage: number;
    voix31: number;
    voix31_par_inscrits_percentage: number;
    voix31_par_exprimes_percentage: number;
    voix32: number;
    voix32_par_inscrits_percentage: number;
    voix32_par_exprimes_percentage: number;
    voix33: number;
    voix33_par_inscrits_percentage: number;
    voix33_par_exprimes_percentage: number;
    voix34: number;
    voix34_par_inscrits_percentage: number;
    voix34_par_exprimes_percentage: number;
    voix35: number;
    voix35_par_inscrits_percentage: number;
    voix35_par_exprimes_percentage: number;
    voix36: number;
    voix36_par_inscrits_percentage: number;
    voix36_par_exprimes_percentage: number;
    voix37: number;
    voix37_par_inscrits_percentage: number;
    voix37_par_exprimes_percentage: number;
    voix38: number;
    voix38_par_inscrits_percentage: number;
    voix38_par_exprimes_percentage: number;
  }

export type ElectionUE2024ResultWithoutMetadata = Omit<ElectionUE2024Result, "code_commune" | "label_commune" | "code_bv">