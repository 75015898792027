import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { ElectionUE2024Result } from "../types/result.types";
import { useElectionData } from "./data";

type DisplayResultType = {
  display: "show";
  by: "circo" | "commune" | "bv";
  results: ElectionUE2024Result[];
};

type ResultType = DisplayResultType | { display: "hide" };

type Actions = {
  showCircoResult: (codeCirco: string) => void;
  showCommuneResult: (codeCommune: string) => void;
  showBVResult: (codeCommune: string, codeBV: number) => void;
  hideResult: () => void;
};

type ResultHookValue = ResultType & Actions;

const ResultContext = React.createContext<ResultHookValue | null>(null);

export const useResult = () => {
  const context = React.useContext(ResultContext);
  if (!context) {
    throw new Error("useResult must be used within a ResultProvider");
  }
  return context;
};

export const ResultProvider = ({ children }: PropsWithChildren<{}>) => {
  const [result, setResult] = useState<ResultType>({ display: "hide" });
  const electionData = useElectionData();

    useEffect(() => {
        if (electionData && electionData.status === "loaded") {
            setResult({
                display: "show",
                by: "circo",
                results: electionData.resultat
            })
        }
    }, [electionData])

  const hideResult = useCallback(() => {
    setResult({ display: "hide" });
  }, []);

  const showCircoResult = useCallback(
    (codeCirco: string) => {
      if (!electionData || electionData.status === "loading") {
        hideResult();
        return;
      }
      const communeCirco = electionData.communes.filter(
        (c) => c.code_circo === codeCirco
      );
      const circoResult = electionData.resultat.filter((r) =>
        communeCirco.find((c) => c.code_commune === r.code_commune)
      );
      setResult({
        display: "show",
        by: "circo",
        results: circoResult,
      });
    },
    [electionData, hideResult]
  );

  const showCommuneResult = useCallback(
    (codeCommune: string) => {
      if (!electionData || electionData.status === "loading") {
        hideResult();
        return;
      }
      const communeResult = electionData.resultat.filter(
        (r) => r.code_commune === codeCommune
      );
      setResult({
        display: "show",
        by: "commune",
        results: communeResult,
      });
    },
    [electionData, hideResult]
  );

  const showBVResult = useCallback(
    (codeCommune: string, codeBV: number) => {
      if (!electionData || electionData.status === "loading") {
        hideResult();
        return;
      }
      const bvResult = electionData.resultat.filter(
        (r) => r.code_commune === codeCommune && r.code_bv === codeBV
      );
      setResult({
        display: "show",
        by: "bv",
        results: bvResult,
      });
    },
    [electionData, hideResult]
  );

  const hookValue = useMemo(
    () => ({
      ...result,
      showCircoResult,
      showCommuneResult,
      showBVResult,
      hideResult,
    }),
    [result, showCircoResult, showCommuneResult, showBVResult, hideResult])

  return (
    <ResultContext.Provider value={hookValue}>{children}</ResultContext.Provider>
  );
};
