import { ShapefileLayer } from "./ShapefileLayer"
import { useElectionData } from "../../context/data"

type CircoLayerProps = {
}
const CircoLayer = ({ }: CircoLayerProps) => {
    const result = useElectionData()
    if (result.status === "loading") return null
    const {circo} = result
    if (!circo.geojson) return null
    return (
        <ShapefileLayer style={{
            opacity: 0.5,
            fillOpacity: 0.2,
        }} data={JSON.parse(circo.geojson)}/>
    )
}

export { CircoLayer }