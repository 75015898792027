import { useEffect, useState } from "react";
import { GeoJSON, GeoJSONProps } from "react-leaflet";
import shp from "shpjs"
import { supabase } from "../../lib/database";

type ShapefileLayerProps = {
    // url: string
} & GeoJSONProps

export const ShapefileLayer = ({ ...geoJsonProps }: ShapefileLayerProps) => {
    return <GeoJSON {...geoJsonProps} />
  };
