import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
type PanelProps = {
  className?: string
};

const Panel = ({ children, className }: PropsWithChildren<PanelProps>) => {
  return ReactDOM.createPortal(
    <div className="relative">
      <div className={cn("fixed top-0 right-0 h-full z-[400]", className)}>
        {children}
      </div>
    </div>, document.body
  )
};

export { Panel };
