import { useResult } from "@/context/result";
import { useElectionData } from "@/context/data";
import { Panel } from "@/components/ui/panel";
import { Results } from "../results";
import { flatUE2024Results } from "@/lib/ue2024result";

export const ResultPanel = () => {
  const data = useElectionData();
  const result = useResult();

  if (
    !data ||
    data.status === "loading" ||
    !result ||
    result.display === "hide"
  )
    return null;

  return (
    <Panel>
      <div className="bg-white m-4 p-4 max-w-[500px] flex flex-col max-h-[calc(100svh-2rem)]">
        {result.by === "circo" && (
          <>
            <h2 className="text-xl font-bold h-[100px]">
              Résultats pour la circonscription {data.circo.code_circo}
              {data.communes.map((c) => {
                return (
                  <li
                    key={c.code_commune}
                    onClick={() => result.showCommuneResult(c.code_commune)}
                  >
                    {
                      result.results.find(
                        (r) => r.code_commune === c.code_commune
                      )?.label_commune
                    }
                  </li>
                );
              })}
            </h2>
          </>
        )}
        {result.by === "commune" && (
          <>
            <h2 className="text-xl font-bold h-[100px]">
              Résultats pour {result.results[0].label_commune}
              <div>
                <span onClick={() => result.showCircoResult(data.codeCirco)}>
                  {data.codeCirco}
                </span>
              </div>
            </h2>
          </>
        )}
        {result.by === "bv" && (
          <>
            <h2 className="text-xl font-bold h-[100px]">
              Résultats pour le bureau de vote {}
            </h2>

          </>
        )}
                    <div className="h-[calc(100svh-2rem-100px-2rem)]">
              <Results results={flatUE2024Results(result.results)} />
            </div>
      </div>
    </Panel>
  );
};