import { LatLngExpression } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import { CircoLayer } from "./CircoLayer";
import { BVLayer } from "./BVLayer";
import { ElectionDataProvider } from "../../context/data";
import { ResultPanel } from "../ResultPanel/ResultPanel";
import { ResultProvider } from "../../context/result";

const position: LatLngExpression = {
  lat: 48.90901842626137,
  lng: 2.400426864624024,
};
export const ElectMap = () => {
  return (
    <ElectionDataProvider codeCirco={"9306"}>
      <ResultProvider>
      <MapContainer center={position} zoom={14} style={{ height: "100svh" }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <CircoLayer />
        <BVLayer />
        <ResultPanel />
      </MapContainer>
      </ResultProvider>
    </ElectionDataProvider>
  );
};
