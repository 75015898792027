
export enum NuanceUE2024 {
    Div = "LDIV",
    Rec = "LREC",
    Fi = "LFI",
    RN = "LRN",
    Vec = "LVEC",
    Exg = "LEXG",
    ENS = "LENS",
    ECO = "LECO",
    LR = "LLR",
    DVG = "LDVG",
    Exd = "LEXD",
    Ug = "LUG",
    Dvd = "LDVD",
    Com = "LCOM",
}

export const ListeUE2024Colors = [
    "#EEEEEE", // LISTE CITOYENNE
    "#EEEEEE", // LISTE CITOYENNE
    "#404040", // LISTE RECONQUETE
    "#CC2443", // LISTE FI
    "#0D378A", // LISTE RN
    "#00C000", // LISTE EELV
    "#FFC0C0", // LISTE MUSLIM
    "#8D2E88", // PARTI ANIMALISTE
    "#BB0000", // PARTI COMMUNISTE REVOLUTIONNAIRE
    "#EEEEEE", // PARTI PIRATE
    "#FFEB00", // MACRON
    "#FFC0C0", // CENTRE GAUCHE
    "#77FF77", // LISTE ECOLO
    "#77FF77", // LISTE ECOLO
    "#118088", // ASSELINAU
    "#77FF77", // LISTE ECOLO
    "#EEEEEE", // LISTE CITOYENNE
    "#0066CC", // LR
    "#BB0000", // LO
    "#FFC0C0", // CENTRE GAUCHE
    "#FFC0C0", // CENTRE GAUCHE
    "#BB0000", // EXG
    "#BB0000", // EXG
    "#8040C0", // EXD
    "#404040", // EXD
    "#404040", // EXD
    "#FF8080", // GLUCKSMAN
    "#BB0000", // EXG
    "#26C4EC", // LASALLE
    "#404040", // EXD
    "#F1D1DC", // DVG
    "#EEEEEE", // LISTE CITOYENNE
    "#DD0000", // COCO
    "#DCDCDC", // LISTE CITOYENNE
    "#77FF77", // LISTE ECOLO
    "#BB0000", // EXG
    "#EEEEEE", // LISTE CITOYENNE
    "#404040", // EXD
]