import { ElectionUE2024ResultWithoutMetadata } from "@/types/result.types";

export const getCandidatesOrder = (results: ElectionUE2024ResultWithoutMetadata) => {
    const voixKeys = Object.keys(results).filter(key => key.startsWith('voix') && !key.endsWith('_percentage'));
    
    //@ts-ignore
    voixKeys.sort((a, b) => results[b] - results[a]);
  
    return voixKeys;
}
export function flatUE2024Results(results: ElectionUE2024ResultWithoutMetadata[]): ElectionUE2024ResultWithoutMetadata {
    const totals: Partial<ElectionUE2024ResultWithoutMetadata> = {};
    const count = results.length;
  
    results.forEach(result => {
      for (let key in result) {
        //@ts-ignore
        if (result.hasOwnProperty(key) && typeof result[key] === 'number' && key !== 'code_bv') {
        //@ts-ignore
            totals[key] = (totals[key] || 0) + result[key];
        }
      }
    });
  
    const averagesAndSums: Partial<ElectionUE2024ResultWithoutMetadata> = {};
    
    for (const key in totals) {
      if (totals.hasOwnProperty(key)) {
        if (key.endsWith('_percentage')) {
        //@ts-ignore
          averagesAndSums[key] = (totals[key] as number) / count;
        } else {
        //@ts-ignore
          averagesAndSums[key] = totals[key];
        }
      }
    }

    return averagesAndSums as ElectionUE2024ResultWithoutMetadata
  }
  