import React, { PropsWithChildren, useEffect, useState } from "react"
import { supabase } from "../lib/database";
import { ElectionUE2024Result, parseRawElectionResultUE2024 } from "../types/result.types";

type ElectionDataType = {
    status: "loaded",
    codeCirco: string,
    circo: Circo,
    communes: CommuneByCirco[],
    bv: Record<CommuneByCirco['code_commune'], BV[]>,
    resultat: ElectionUE2024Result[]
    listes: ListeUe2024[]
}

type ElectionDataReturnType = ElectionDataType | {status: "loading"}
const ElectionDataContext = React.createContext<ElectionDataReturnType | null>(null);

export const useElectionData = () => {
    const context = React.useContext(ElectionDataContext);
    if (!context) {
        throw new Error("useElectionData must be used within a ElectionDataProvider");
    }
    return context;
};

type ElectionDataProviderProps = {
    codeCirco: string
}

export const ElectionDataProvider = ({
    codeCirco,
    children
}: PropsWithChildren<ElectionDataProviderProps>) => {
    const [data, setData] = useState<ElectionDataReturnType | null>({status: "loading"})

    useEffect(() => {
        const fetchData = async () => {
            const { data: circo, error: circoError } = await supabase
                .from("circo")
                .select("*")
                .eq("code_circo", codeCirco);
            if (!circo || !circo.length || circoError) {
                console.log(circoError);
                return;
            }

            const { data: communeByCirco, error } = await supabase 
                .from("circo_commune")
                .select("*")
                .eq("code_circo", codeCirco);
            if (!communeByCirco || error) {
                console.log(error);
                return;
            }

            const { data: bv, error: bvError } = await supabase
                .from("bureau_vote")
                .select("*")
                .in("code_commune", communeByCirco?.map((d) => d.code_commune));
            if (!bv || bvError) {
                console.log(bvError);
                return;
            }


            const { data: resultat, error: resultatError } = await supabase
                .from("resultat_ue_2024")
                .select('*')
                .in('Code commune', communeByCirco?.map((d) => d.code_commune))
            if (!resultat || resultatError) {
                console.log(resultatError);
                return;
            }
            const electionResultUE2024 = parseRawElectionResultUE2024(resultat)

            const { data: listes, error: listesError } = await supabase
                .from("liste_candidat")
                .select('*')
            if (!listes || listesError) {
                console.log(listesError);
                return;
            }

        
            setData({
                status: "loaded",
                codeCirco,
                circo: circo[0],
                communes: communeByCirco,
                bv: bv.reduce<Record<CommuneByCirco['code_commune'], BV[]>>((acc, d) => {
                    if (d.code_commune in acc)
                        acc[d.code_commune].push(d)
                    else
                        acc[d.code_commune] = [d]
                    return acc
                }, {}),
                resultat: electionResultUE2024,
                listes
            })
        }
        fetchData()
    }, [codeCirco])


    return (
        <ElectionDataContext.Provider value={data}>
            {children}
        </ElectionDataContext.Provider>
    )
}