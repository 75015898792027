import { LayerGroup, Marker } from "react-leaflet";
import { useElectionData } from "../../context/data";
import { useResult } from "@/context/result";

type BVLayerProps = {
};

const BVLayer = ({ }: BVLayerProps) => {
  const electionData = useElectionData()
  const result = useResult()
  if (electionData.status === "loading") return null

  const {bv: bureauVote} = electionData
  const bvs = Object.keys(bureauVote).map((key) => bureauVote[key]).flat()

  const onBVClick = (bv: BV) => {
    result.showBVResult(bv.code_commune, bv.code_bv)
  }

  if (!bureauVote) return null;
  return (
    <LayerGroup>
      {bvs.map((bv) => {
        if (!bv.lat || !bv.lon) return null;
        return (
          <Marker
            key={bv.code_commune + bv.code_bv}
            position={[bv.lat, bv.lon]}

            eventHandlers={{
                click: () => onBVClick(bv)
            }}
          />
        );
      })}
    </LayerGroup>
  );
};

export { BVLayer };
