import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useElectionData } from "@/context/data";
import { getCandidatesOrder } from "@/lib/ue2024result";
import { ListeUE2024Colors, NuanceUE2024 } from "@/types/listeUE2024.types";
import {
  ElectionUE2024Result,
  ElectionUE2024ResultWithoutMetadata,
} from "@/types/result.types";

type ResultsProps = {
  results: ElectionUE2024ResultWithoutMetadata;
};

export const Results = ({ results }: ResultsProps) => {
  const data = useElectionData();
  if (!data || data.status === "loading") return null;

  const candidatesOrder = getCandidatesOrder(results);

  return (
    <div className="h-full">
      <div className="h-[100px]">
        <Result
          label="Votants"
          value_percentage={results.votants_percentage}
          value_raw={results.votants}
          total_raw={results.inscrits}
        />
      </div>
      <ScrollArea className="h-[calc(100%-100px)]">
        <ol className="flex flex-col gap-4">
          {candidatesOrder.map((candidate) => {
            // voix2: number;
            // voix2_par_inscrits_percentage: number;
            // voix2_par_exprimes_percentage: number;

            const candidateNumber = parseInt(candidate.replace("voix", ""));
            const liste = data.listes.find(
              (l) => l.numero_panneau === candidateNumber
            );
            //@ts-ignore
            const candidateResult = results[`voix${candidateNumber}`] as number;
            //@ts-ignore
            const candidatePercentage = results[
              `voix${candidateNumber}_par_exprimes_percentage`
            ] as number;

            return (
              <li key={candidate}>
                <Result
                  label={liste?.label || candidate}
                  value_percentage={candidatePercentage}
                  value_raw={candidateResult}
                  color={ListeUE2024Colors[candidateNumber - 1]}
                />
              </li>
            );
          })}
        </ol>
      </ScrollArea>
    </div>
  );
};

type ResultProps = {
  label: string;
  nuance?: string;
  value_percentage: number;
  value_raw: number;
  total_raw?: number;
  color?: string;
};
const Result = ({
  label,
  nuance,
  value_percentage,
  value_raw,
  total_raw,
  color,
}: ResultProps) => {
  return (
    <div>
      {!nuance && <label>{label}</label>}
      {nuance && <label>{`${label} (${nuance})`}</label>}
      <div>
        <div className="flex">
          <span>{value_percentage.toPrecision(4)}%</span>
        </div>
        <Progress value={value_percentage} color={color} />
        <div className="flex justify-between">
          <label className="text-sm">{value_raw}</label>
          {total_raw && <label className="text-sm">{total_raw}</label>}
        </div>
      </div>
    </div>
  );
};
